import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../assets/styles/colors";
import { MainButton } from "../components/MainButton";
import { TextInput } from "../components/TextInput";
import { useTranslation } from "react-i18next";
import { useUserActions, useUserState } from "../store/user/hooks";
import { ILoginType } from "../store/user/types";
import bg from '../assets/img/bg.png'
import { PasswordInput } from "../components/PasswordInput";

const SignInPage = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background:#0058A9;
`;

const Box = styled.div`
  width: 800px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: white;
  border: 1px solid #c2fffd;
  box-shadow: rgb(0 0 0 / 5%) 0px 8px 25px;
  padding: 40px 60px;
`;

const StyledInput = styled.input`
  width: 100%;
  max-width: 446px;
  background: #fff;
  border: 0;
  color: var(--Black, #000);
  border-bottom: 1px solid #000;
  font-family: "IBM Plex Sans Hebrew",serif;
  font-size: 26px;
  padding-bottom: 20px;
  margin-bottom: 16px;
  
  &:focus {
    outline-width: 0;
  }
`;


const StyledBtn = styled.button`
  width: 100%;
  cursor: pointer;
  margin-top: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  color: var(--Black, #000);
  text-align: center;
  border-radius: 0;
  background: #fff;
  border:1px solid #000;
  max-width: 179px;
  height: 53px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
`;

const ErrorMessage = styled.p`
  text-align: center;
  color: red;
  margin: 5px 0; 
`

const SignIn = React.memo(() => {
  const { t } = useTranslation()
  const {errorMessage} = useUserState()
  const {onLogin} = useUserActions()
  const [loginData, setLoginData]: [ILoginType, any] = useState({
    username: "",
    password: "",
  });

  const handleLogin = () => {
    onLogin(loginData)
  }

  return (
    <SignInPage>
      <form onSubmit={(e) => e.preventDefault()}>
        <Box>
          <StyledInput
            placeholder={t('sign-in_login-placeholder')}
            value={loginData.username}
            onChange={(e) => setLoginData((prev: ILoginType) => ({ ...prev, username:  e.target.value }))}
          />
          <StyledInput
            placeholder={t('sign-in_password-placeholder')}
            value={loginData.password}
            onChange={(e) =>
              setLoginData((prev: ILoginType) => ({ ...prev, password: e.target.value }))
            }
            type = "password"
          />
          <StyledBtn
            onClick={handleLogin}
            color="orange"
            disabled={!loginData.username || !loginData.password}
          >
            {t('sign-in_sign-in')}
          </StyledBtn>
          {errorMessage && <ErrorMessage>{t(errorMessage)}</ErrorMessage>}
        </Box>
      </form>
    </SignInPage>
  );
});

export default SignIn;
