import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { HistoryCard } from "../components/HistoryCard";
import { Modal } from "../components/Modal";
import { Preloader } from "../components/Preloader";
import { Title } from "../components/Title";
import { useAppActions } from "../store/app/hooks";
import { IHistoryEmail } from "../store/posts";
import { usePostsActions } from "../store/posts/hooks";
import {postsCurrentDraftSelector, postsIsFetchingSelector, postsMailDraftsSelector} from "../store/posts/selectors";
import {TextInput} from "../components/TextInput";
import SinglePostEditor from "./SinglePostEditor";



const CenteredText = styled.p`
    text-align: center;
`

const Content = styled.div`
  max-width: 1090px;
  padding: 30px 20px 20px;
  height: calc(80vh - 90px);
  overflow-y: auto;
`;

const DraftsView = React.memo(() => {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>("");
  const mailDrafts = useSelector(postsMailDraftsSelector)
  const isFetching = useSelector(postsIsFetchingSelector)
  const { onSetModal } = useAppActions();
  const { onGetDrafts, onDeleteDraft, onSetCurrentDraft } = usePostsActions();

  useEffect(() => {
    onGetDrafts();
  }, []);

  const handleClick = (draft: IHistoryEmail) => {
    onSetCurrentDraft(draft)
  }
  const currentDraft = useSelector(postsCurrentDraftSelector);
  return (
    <Modal title={t("drafts-title")} onClose={() => onSetModal(null)}>
      <Content>
      {isFetching && <Preloader />}
      <Container>
        {!isFetching && !!mailDrafts.length && <StyledInput
            searchBtn={true}
            value={search}
            onChange={setSearch}
            placeholder={t("filters-search")}
        />}
        {mailDrafts.length === 0 && !isFetching && <CenteredText>{t('drafts-no_drafts')}</CenteredText>}
        {mailDrafts.filter(item => item.subject.toLowerCase().includes(search.toLowerCase())).map(draft => (
            <HistoryCard
                key={draft.id}
                onDelete={() => onDeleteDraft(draft.id)}
                item={draft}
                onClick={() => handleClick(draft)}
            />
        ))}
      </Container>
        {currentDraft && (
            <SinglePostEditor
                draft={currentDraft}
                onClose={() => onSetCurrentDraft(null)}
                posts={[]}
                post={null}
            />
        )}
    </Content>
    </Modal>
  );
});

export default DraftsView;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const StyledInput = styled(TextInput)`
  width: auto;
  flex-grow: 1;
`;
