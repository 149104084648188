import {useTranslation} from "react-i18next";
import {useUserState} from "../../store/user/hooks";
import {usePostsActions} from "../../store/posts/hooks";
import React, {useEffect, useState} from "react";
import {handle} from "../../api";
import {Filters} from "../../api/Filters";
import styled from "styled-components";
import {PrimaryButton} from "../../components/PrimaryButton";
import {PrimaryButtonVariants} from "../../components/PrimaryButton/PrimaryButton";
import {TextInput} from "../../components/TextInput";
import {DropdownSearch} from "../../components/DropdownSearch";

const emptyOption = { label: "", value: null };

const FiltersView = ()=>{
    const { t } = useTranslation();
    const { filters, token } = useUserState();
    const { onApplyFilter } = usePostsActions();
    //inputs
    const [search, setSearch] = useState<string>("");
    const [tag, setTag] = useState(emptyOption);
    const [gTag, setGtag] = useState(emptyOption);
    const [cat, setCat] = useState(emptyOption);
    //dropdown options
    const [tagOptions, setTagOptions] = useState<any[]>([]);
    const [catOptions, setCatOptions] = useState<any[]>([]);
    const [gTagOptions, setGtatOptions] = useState<any[]>([]);

    //fetch filters data
    useEffect(() => {
        if (token) {
            handle(Filters.getFilters(token)).then(([dataRes, dataErr]) => {
                if (dataRes) {
                    const { tags, g_tags, cats } = dataRes;
                    setTagOptions(tags.map((t: string) => ({ label: t, value: t })));
                    setCatOptions(cats.map((c: string) => ({ label: c, value: c })));
                    setGtatOptions(g_tags.map((c: string) => ({ label: c, value: c })));
                }
            });
        }
    }, [token]);

    //fill inputs according to current filter values
    useEffect(() => {
        setSearch(filters?.free_search ? filters.free_search : "");
        if (filters?.cat) setCat(catOptions.find((c) => c.value === filters.cat));
        if (filters?.tag) setTag(tagOptions.find((t) => t.value === filters.tag));
        if (filters?.g_tag)
            setGtag(gTagOptions.find((g) => g.value === filters.g_tag));
        //set empty options when value is null
        if (!filters?.cat) setCat(emptyOption);
        if (!filters?.tag) setTag(emptyOption);
        if (!filters?.g_tag) setGtag(emptyOption);
    }, [filters, catOptions, tagOptions, gTagOptions]);

    const handleApply = () => {
        const data = {
            free_search: search ? search : null,
            tag: tag.value,
            cat: cat.value,
            g_tag: gTag.value,
        };
        onApplyFilter(data);
    };

    const handleClear = () => {
        const data = {
            free_search: null,
            tag: null,
            cat: null,
            g_tag: null,
        };
        onApplyFilter(data);
    };

    return(
        <Container>
            <ButtonsContainer>
                <PrimaryButton onClick = {handleClear} variant={PrimaryButtonVariants.pinkish}>{t("clear-filter")}</PrimaryButton>
                <PrimaryButton onClick = {handleApply} variant={PrimaryButtonVariants.white}>{t("apply-filter")}</PrimaryButton>
            </ButtonsContainer>
            <FiltersContainer>
                <StyledInput
                    searchBtn={true}
                    value={search}
                    onChange={setSearch}
                    placeholder={t("filters-search")}
                    label={t("filters-search")}
                />

                <StyledDropdown
                    placeholder={t("filter-choose-variant")}
                    label={t("filters-tag")}
                    onSelect={setTag}
                    options={tagOptions}
                    value={tag}
                />
                <StyledDropdown
                    placeholder={t("filter-choose-variant")}
                    label={t("filters-cat")}
                    onSelect={setCat}
                    options={catOptions}
                    value={cat}
                />
                <StyledDropdown
                    placeholder={t("filter-choose-variant")}
                    label={t("filters-g_tag")}
                    onSelect={setGtag}
                    options={gTagOptions}
                    value={gTag}
                />
            </FiltersContainer>
        </Container>
    )
}

export default FiltersView;

const Container = styled.div`
  padding: 0 60px;
  display: flex;
  gap: 40px;
  width: 100%;

  flex-direction: row-reverse;
  align-items: flex-end;
`

const ButtonsContainer = styled.div`
    display: flex;
  height: fit-content;
  flex-direction: row-reverse;
  margin-top: 37px;
  gap: 18px;
`

const FiltersContainer = styled.div`
    display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 40px;
  height: fit-content;
  align-items: center;
`
const StyledInput = styled(TextInput)`
  width: auto;
  flex-grow: 1;
`;

const StyledDropdown = styled(DropdownSearch)`
  width: auto;
  flex-grow: 1;
  .searchable-dropdown > div{
    background: #fff;
  }
`;
