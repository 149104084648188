import styled, {css} from "styled-components";


export enum PrimaryButtonVariants{
    white,
    pinkish
}
const PrimaryButton = styled.button<{variant:PrimaryButtonVariants}>`
  all:unset;
  cursor: pointer;
  width: 94px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  border: 1px solid var(--Grey, #BCBCBC);
  line-height: normal;
  transition: .2s ease-in;
  ${props => props.variant === PrimaryButtonVariants.white && css`
    background: #fff;
    &:hover{
      background: #e6e6e6;
    }
  `}}
    ${props => props.variant === PrimaryButtonVariants.pinkish && css`
        background: #FBDADA;
        &:hover{
          background: #f6b0b0;
        }
    `}}
`

export default PrimaryButton;
