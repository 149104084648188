import {IMultiPost, ISinglePost} from "../../store/posts";
import React, {FC, useMemo} from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {postsClientsFilterSelector, postsIsFetchingSelector, postsNewPostsSelector} from "../../store/posts/selectors";
import {usePostsActions} from "../../store/posts/hooks";
import {IPostCardClient} from "../../store/clients";
import {PostList} from "../index";
import {Preloader} from "../../components/Preloader";
import {PrimaryButton} from "../../components/PrimaryButton";
import {PrimaryButtonVariants} from "../../components/PrimaryButton/PrimaryButton";

interface IProps{
    posts: Array<ISinglePost |IMultiPost>
    title: string
}
const PostsView:FC<IProps> = ({title, posts})=>{
    const { t } = useTranslation();
    const clientsFilter = useSelector(postsClientsFilterSelector)
    const isFetching = useSelector(postsIsFetchingSelector)
    const newPosts = useSelector(postsNewPostsSelector)
    const {onUpdateNewPosts } =
        usePostsActions();

    //Case when set clients filter
    const filteredPosts: Array<ISinglePost | IMultiPost> = useMemo(() => {
        return posts.filter((post: ISinglePost | IMultiPost) => {
            if (clientsFilter && !post.clients) return false;
            if (clientsFilter && post.clients) {
                return post.clients.some(
                    (client: IPostCardClient) => client.id === clientsFilter.id
                );
            }
            if (!clientsFilter) return true;
            return false;
        });
    }, [posts, clientsFilter]);
    return (
        <Container>
            <Title>{title}</Title>
            <PostsListContainer>
                {newPosts.length > 0 && !isFetching && (
                    <PrimaryButton variant={PrimaryButtonVariants.white} color="orange" onClick={onUpdateNewPosts}>
                        {`${t("emails_new-available")}`}
                    </PrimaryButton>
                )}
                {isFetching && <StyledPreloader />}
                {!isFetching && <PostList posts={filteredPosts}/>}
            </PostsListContainer>
        </Container>
    )
}

export default PostsView;

const Container = styled.div`
  width: 100%;
  padding: 15px;
  background: #fff;
  aspect-ratio: 2.8;
  display: flex;
  flex-direction: column;
  border: 2px solid #0058A9;
`

const Title = styled.div`
  color: #0058A9;
  text-align: right;
  font-family: "IBM Plex Sans Hebrew",serif;
  font-size: 32px;
  font-weight: 500;
`

const PostsListContainer = styled.div`
  overflow: auto;
  margin-top: 16px;
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 20px;
`
const StyledPreloader = styled(Preloader)`
  grid-column: span 2;
`;
