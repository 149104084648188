import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import styled from "styled-components";
import {HistoryCard} from "../components/HistoryCard";
import {MainButton} from "../components/MainButton";
import {Modal} from "../components/Modal";
import {Preloader} from "../components/Preloader";
import {TextInput} from "../components/TextInput";
import {Title} from "../components/Title";
import {useAppActions} from "../store/app/hooks";
import {IHistoryEmail} from "../store/posts";
import {usePostsActions} from "../store/posts/hooks";
import {
  postsCurrentDraftSelector,
  postsHistoryEmailsSelector,
  postsHistorySearchQuerySelector,
  postsIsFetchingHistorySelector
} from "../store/posts/selectors";
import HistoryItem from "./HistoryItem";
import SinglePostEditor from "./SinglePostEditor";
import {PrimaryButton} from "../components/PrimaryButton";
import {PrimaryButtonVariants} from "../components/PrimaryButton/PrimaryButton";

const Content = styled.div`
  max-width: 1090px;
  padding: 0 20px 20px;
  height: calc(75vh - 120px);
  overflow-y: auto;
`;

const StyledClose = styled(MainButton)`
  padding: 11px 0;
  text-align: center;
  width: 150px;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 10px;
`;

const SearchInput = styled(TextInput)`
  flex-grow: 1;
  margin-bottom: 15px;
  input {
    padding: 8px 15px;
  }
`;

const EmailsHistory = React.memo(() => {
  const { t } = useTranslation();
  const historyEmails = useSelector(postsHistoryEmailsSelector)
  const isFetchingHistory = useSelector(postsIsFetchingHistorySelector)
  const historySearchQuery = useSelector(postsHistorySearchQuerySelector)
  const { onSetModal } = useAppActions();
  const { onGetHistory, onClearHistory, onSetCurrentDraft, onSetSearchQuery } = usePostsActions();
  const currentDraft = useSelector(postsCurrentDraftSelector);
  const [openedItem, setOpenedItem] = useState<IHistoryEmail | null>(null);
  console.log(openedItem, "???")

  const ScrollableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return () => {
      onClearHistory()
      onSetSearchQuery('')
    }
  }, []);

  useEffect(() => {
    onGetHistory()
  }, [historySearchQuery])

  useEffect(() => {
    if (historyEmails.length === 0 && !historySearchQuery) {
      onGetHistory();
    }
  }, [historyEmails, historySearchQuery]);

  useEffect(() => {
    ScrollableRef?.current?.addEventListener("scroll", handleScroll);
    return () =>
      ScrollableRef?.current?.removeEventListener("scroll", handleScroll);
  }, [ScrollableRef, historySearchQuery]);

  const handleScroll = ({ target }: any) => {
    const onBottom =
      target.scrollTop + target.offsetHeight >= target.scrollHeight - 30;
    if (onBottom && historySearchQuery === '') {
      console.log(historySearchQuery)
      onGetHistory()
    }
  }

  const handleSend = (item: IHistoryEmail) => {
    onSetCurrentDraft(item);
  };


  return (
    <>
      <Modal title = {t("emails_send-history_title")} onClose={() => onSetModal(null)}>
        <Content ref={ScrollableRef}>
            <SearchInput
              label="Search"
              value={historySearchQuery}
              onChange={onSetSearchQuery}
              searchBtn={true}
            />
          <ListContainer>
            {historyEmails.map((item) => (
                <HistoryCard
                    onSend={() => handleSend(item)}
                    key={item.id}
                    item={item}
                    onClick={() => setOpenedItem(item)}
                />
            ))}
          </ListContainer>
          {isFetchingHistory && <Preloader />}
        </Content>
        <ButtonContainer>
          <PrimaryButton onClick={() => onSetModal(null)} variant={PrimaryButtonVariants.pinkish}>
            {t("emails_send-history_close")}
          </PrimaryButton>
        </ButtonContainer>
      </Modal>
      {currentDraft && (
        <SinglePostEditor
          draft={currentDraft}
          onClose={() => onSetCurrentDraft(null)}
          posts={[]}
          post={null}
        />
      )}
      {openedItem && <HistoryItem item={openedItem} onBack={() => setOpenedItem(null)} />}
    </>
  );
});

export default EmailsHistory;


const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  padding: 20px 0;
  padding-left: 20px;
`
