import {FilterClient, IMultiPost, ISinglePost} from "../../store/posts";
import {FC, useCallback} from "react";
import {useSelector} from "react-redux";
import {postsClientsFilterSelector} from "../../store/posts/selectors";
import {usePostsActions} from "../../store/posts/hooks";
import {PostItem} from "../index";
import {useAppActions} from "../../store/app/hooks";

interface IProps {
    posts: Array<ISinglePost | IMultiPost>;
}
const PostsList:FC<IProps> = ({posts})=>{
    const clientsFilter = useSelector(postsClientsFilterSelector)
    const { onSetEditor, onSetClientsFilter } = usePostsActions();
    const { onSetModal } = useAppActions();



    const handleSelectClient = useCallback((client: FilterClient) => {
        if (client.id === clientsFilter?.id) onSetClientsFilter(null);
        if (client.id !== clientsFilter?.id) onSetClientsFilter(client);
    }, [clientsFilter])

    const onOpenEditor = useCallback((post: ISinglePost | IMultiPost) => {
        onSetEditor(post);
        onSetModal("email-editor");
    }, [])

    return (
        <>
            {posts.map((p, index)=>{
                if(!p._type){
                    return(
                        <PostItem item={p as ISinglePost} onEmail={()=>onOpenEditor(p)} onSelectClient={handleSelectClient}/>
                    )
                }
            })}
        </>
    )
}

export default PostsList;
