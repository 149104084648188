import styled from "styled-components";

const ItemsBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  max-height: 900px;
  overflow: auto;
`;

export default ItemsBox;
