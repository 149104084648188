export const committees: {[key: string]: string} = {
    "ועדת הכנסת": "knesset",
    "ועדת הכספים": "Finance",
    "ועדת הכלכלה": "Economics",
    "ועדת החוץ והביטחון": "ForeignAffairs",
    "ועדת הפנים והגנת הסביבה": "InternalAffairs",
    "ועדת החוקה, חוק ומשפט": "huka",
    "ועדת העלייה, הקליטה והתפוצות": "Immigration",
    "ועדת החינוך, התרבות והספורט": "Education",
    "ועדת העבודה והרווחה": "Labor",
    "הוועדה לענייני ביקורת המדינה": "StateControl",
    "הוועדה לקידום מעמד האישה ולשוויון מגדרי": "Women",
    "ועדת המדע והטכנולוגיה": "Science",
    "ועדת הבריאות": "health",
    "ועדת ביטחון הפנים": "InternalSecurity",
    "ועדת מיזמי תשתית לאומיים מיוחדים ושירותי דת יהודיים": "Infrastructure",
    "הוועדה המיוחדת לענייני התמכרויות, סמים ואתגרי הצעירים בישראל": "Drugs",
    "הוועדה המיוחדת לזכויות הילד": "Children",
    "הוועדה המיוחדת לעובדים זרים": "Foreign",
    "הוועדה המיוחדת לענייני החברה הערבית": "ArabSociety",
    "הוועדה המיוחדת לעניין הקרן לאזרחי ישראל": "GasFund",
    'הוועדה המיוחדת לדיון בהצעת חוק הסדרת השימוש בקנאביס למטרות רפואיות, התשפ"ב-2021 (פ/2245/24)': "cannabis",
    "הוועדה המיוחדת לפניות הציבור": "Petitions",
    "ועדת האתיקה": "Ethics"
}