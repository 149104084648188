import React from 'react'
import styled from "styled-components";
import { colors } from "../../assets/styles/colors";
import { ITitleProps } from "./types";

const StyledTitle = styled.h1`
  text-align: center;
  font-size: 32px;
  line-height: 45px;
  color: ${colors.graphite_6};
`;



const Title = React.memo((props:ITitleProps) => {
  return <StyledTitle className={props.className}>{props.children}</StyledTitle>
})

export default Title
