import styled from "styled-components";
import {
    AddUser,
    BirthdaysEditor, BugReportView,
    ClientsEditor,
    DraftsView,
    EmailsHistory,
    FiltersView,
    HeaderView,
    PostsView, SinglePostEditor
} from "../views";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {postsCurrentDraftSelector, postsEditorPostSelector, postsPostsSelector} from "../store/posts/selectors";
import {useAppActions, useAppState} from "../store/app/hooks";
import {useClientsActions} from "../store/clients";
import {useUserActions, useUserState} from "../store/user/hooks";
import {usePostsActions} from "../store/posts/hooks";
import {useKeywordsActions} from "../store/keywords/hooks";
import {useUsersActions} from "../store/users";
import React, {useEffect, useMemo} from "react";
import {usersWithAdditionalColumn} from "../config/usersWithAdditionalColumn";
import {IMultiPost, ISinglePost} from "../store/posts";
import {composeMultiPosts} from "../utilites/composeMultiPosts";
import KeywordEditor from "../views/KeywordEditor";
import {ClientsFilter} from "../components/ClientsFilter";

const SPEC_FILES_LIST_NAME = "פרוטוקול ועדה"

const firstColumnNodes = ["news",
    "govil",
    "gov_statisctics",
    "govil_data",
    "govil_pdf",
    "antique",
    "antique_trends",
    "news_11",
    "iplan",
    "mavat",
    "dynamic_collector_first",
    "dynamic_collector_second",
    "dynamic_collector_third",
    "isa",
    "boi"
]

const secondColumnNodes = [
    "plenum_session",
    "agendas",
    "person_to_position",
    "query",
    "press_release",
    "committee_session",
    "sg_presidium",
]

const thirdColumnNodes = ["cs_documents"]

const fourthColumnNodes = ["google_news", "news_with_login"]

const EmailsPage = ()=>{
    const { t } = useTranslation();
    const editorPost = useSelector(postsEditorPostSelector);
    const currentDraft = useSelector(postsCurrentDraftSelector);
    const posts = useSelector(postsPostsSelector);
    const { modal } = useAppState();
    const { onGetClients } = useClientsActions();
    const { token, login } = useUserState();
    const { onLogout } = useUserActions();
    const { onGetPosts, onWatchForPosts, onSetEditor } = usePostsActions();
    const { onGetKeywords } = useKeywordsActions();
    const { onGetUsers } = useUsersActions();
    const { onSetModal } = useAppActions()




    const userWithAdditionalColumn = useMemo(() => {
        return usersWithAdditionalColumn.find((user) => user.login === login);
    }, [login]);

    //First column
    const firstColumn = useMemo(() => {
        return posts
            .filter((post) => firstColumnNodes.includes(post._sender))
            .sort((prev, next) => next.date_for_sorting - prev.date_for_sorting);
    }, [posts]);

    //Second column
    const billSinglePosts = useMemo(() => {
        return posts.filter((post) => post._sender === "bill");
    }, [posts])

    const billPosts: Array<ISinglePost | IMultiPost> = useMemo(() => {
        const multiPosts = composeMultiPosts(billSinglePosts);
        const singleFiltered = billSinglePosts.filter(post => {
            let isInMPosts = false
            multiPosts.forEach(m => {
                m.posts.forEach(p => {
                    if (p.id === post.id) isInMPosts = true
                })
            })
            return !isInMPosts
        })

        //logMultiPostsTests(singlePosts)

        return [...multiPosts, ...singleFiltered].sort(
            (prev, next) => next.date_for_sorting - prev.date_for_sorting
        );
    }, [billSinglePosts]);


    const secondColumn = useMemo(() => {
        const filtered = posts.filter(
            (post) => {
                if (!secondColumnNodes.includes(post._sender)) return false
                if (post._sender === "committee_session" && post.files?.hasOwnProperty(SPEC_FILES_LIST_NAME)) return false
                return true
            }
        )
        return [...billPosts, ...filtered].sort((prev, next) => next.date_for_sorting - prev.date_for_sorting)
    }, [posts, billPosts, secondColumnNodes])



    //Third column
    const thirdColumn = useMemo(() => {
        const list = posts
            .filter(p => thirdColumnNodes.includes(p._sender))
            .sort((prev, next) => next.date_for_sorting - prev.date_for_sorting)
        return list
    }, [posts])


    //Fourth column
    const fourthColumn = useMemo(() => {
        return posts
            .filter(
                (post) =>
                    fourthColumnNodes.includes(post._sender)
            )
            .sort((prev, next) => next.date_for_sorting - prev.date_for_sorting);
    }, [posts, fourthColumnNodes]);

    //Additional column
    const additionalPosts = useMemo(() => {
        if (!userWithAdditionalColumn) return [];
        return posts
            .filter((post) => post.tag === userWithAdditionalColumn.tag)
            .sort((prev, next) => next.date_for_sorting - prev.date_for_sorting);
    }, [posts, userWithAdditionalColumn]);


    useEffect(() => {
        onGetPosts();
        onGetClients();
        onGetKeywords();
        onGetUsers();
    }, []);

    useEffect(() => {
        if (token) onWatchForPosts(token);
    }, [token]);

    useEffect(() => {
        console.log("single posts shown", posts.length)
    }, [posts.length]);



    return (
        <Container>
            <HeaderView/>
            <FiltersView/>
            <ClientsFilter />
            {userWithAdditionalColumn && (
                <PostsContainer>
                    <PostsView
                        posts={additionalPosts}
                        title={t("emails_title3")}
                    />
                </PostsContainer>
            )}
            <PostsContainer>
                <PostsView
                    posts={firstColumn}
                    title={t("emails_title2")}
                />
            </PostsContainer>
            <PostsContainer>
                <PostsView
                    posts={secondColumn}
                    title={t("emails_title4")}
                />
            </PostsContainer>
            <PostsContainer>
                <PostsView
                    posts={thirdColumn}
                    title={t("emails_title5")}
                />
            </PostsContainer>
            {/*<PostsContainer>*/}
            {/*    <PostsView*/}
            {/*        posts={fourthColumn}*/}
            {/*        title={t("emails_title1")}*/}
            {/*    />*/}
            {/*</PostsContainer>*/}

            {modal === "email-editor" && editorPost?._type !== "multipost" && (
                <SinglePostEditor
                    draft={currentDraft}
                    posts={posts}
                    post={editorPost as ISinglePost}
                    onClose={() => onSetModal(null)}
                />
            )}
            {modal === "client-editor" && <ClientsEditor />}
            {modal === "keyword-editor" && <KeywordEditor />}
            {modal === "add-user" && <AddUser />}
            {modal === "emails-history" && <EmailsHistory />}
            {modal === "birthdays-editor" && <BirthdaysEditor />}
            {modal === "drafts" && <DraftsView />}
            {modal === "bug_report" && <BugReportView />}
        </Container>
    )
}


export default EmailsPage;


const Container = styled.div`
    width: 100%;
  min-height: 100vh;
  background: #F1F8FF;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
`

const PostsContainer = styled.div`
    padding: 0 77px;
    padding-top: 68px;
  
`
