import styled from "styled-components";
import {useClientsState} from "../../store/clients";
import {useTranslation} from "react-i18next";

import bug_icon from "../../assets/svg/bug-report.svg"
import logout from "../../assets/svg/logout.svg";
import draft from "../../assets/svg/draft-icon.svg";
import user from "../../assets/svg/user-icon.svg";
import editUsers from "../../assets/img/edit-users.png"
import editKeywords from "../../assets/svg/keywords-icon.svg"
import sendHistory from "../../assets/svg/send-history-icon.svg";

import {useUserActions, useUserState} from "../../store/user/hooks";
import {useAppActions} from "../../store/app/hooks";
const HeaderView = ()=>{
    const { clients } = useClientsState();
    const {full_name, is_staff} = useUserState()
    const {onLogout} = useUserActions();
    const { onSetModal } = useAppActions();
    const {t} = useTranslation();

    return (
        <Container>
            <ClientsTitle>
            מספר לקוחות נוכחי
            <span>{clients.length}</span>
            </ClientsTitle>
            <ActionsContainer>
                <StyledButton   onClick={() => onSetModal("bug_report")}>
                    <Icon src = {bug_icon}/>
                    <ButtonTitle>{t("bug-report-title")}</ButtonTitle>
                </StyledButton>
                <StyledButton  onClick={() => onSetModal("drafts")}>
                    <Icon src = {draft}/>
                    <ButtonTitle>{t("draft-btn-title")}</ButtonTitle>
                </StyledButton>
                {is_staff && (
                    <StyledButton onClick={() => onSetModal("add-user")}>
                        <Icon src = {user}/>
                        <ButtonTitle>{t("edit-user-btn-title")}</ButtonTitle>
                    </StyledButton>
                )}
                <StyledButton onClick={() => onSetModal("client-editor")}>
                    <Icon style = {{width:50}} src = {editUsers}/>
                    <ButtonTitle>{t("edit-clients-btn-title")}</ButtonTitle>
                </StyledButton>
                <StyledButton onClick={() => onSetModal("keyword-editor")}>
                    <Icon src = {editKeywords}/>
                    <ButtonTitle>{t("edit-keywords-btn-title")}</ButtonTitle>
                </StyledButton>
                <StyledButton onClick={() => onSetModal("emails-history")}>
                    <Icon src = {sendHistory}/>
                    <ButtonTitle>{t("send-history-icon")}</ButtonTitle>
                </StyledButton>
            </ActionsContainer>
            <LogoutButton onClick = {onLogout}>
                <Icon src = {logout}/>
                <ButtonTitle>{full_name}</ButtonTitle>
            </LogoutButton>
        </Container>
    )
}


export default HeaderView;


const Container = styled.div`
    width: 100%;
  padding: 25px 40px;
  height: 92px;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  background: #0058A9;
  
`

const ClientsTitle = styled.h2`
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  span{
    padding: 8px;
    color: #FE5912;
  }
`


const ActionsContainer = styled.div`
    display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  gap: 34px;
    align-items: center;
`

const LogoutButton = styled.div`
    display: flex;
  align-items: center;
  cursor: pointer;
  gap: 5px;
`


const StyledButton = styled.button`
  border: 0;
  cursor: pointer;
  position: relative;
  background: transparent;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  &:before{
    position: absolute;
    display: block;
    content:"";
    top: 0;
    height: 100%;
    width: 1px;
    left: -30px;
    background: #fff;
  }
`
const ButtonTitle = styled.p`
  color: #FFF;
  object-fit: contain;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

const Icon = styled.img``
