import React from 'react'
import Select from "react-select";
import styled from "styled-components";
import { colors } from "../../assets/styles/colors";

interface IProps {
  label?: string;
  options: { label: string; value: string }[];
  value: any;
  isMulti?: boolean;
  className?: string;
  isReversed?: boolean;
  onSelect: (v: any) => void;
  components?: any;
  placeholder?:string,
}

const StyledDropdown = styled.div`
  //width: 100%;
`;

const LabelStyled = styled.label`
  margin-inline-start: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: ${colors.graphite_5};
`;

const customStyles = {
  dropdownIndicator: (provided: any, state:any) => ({
    color: "#000",
    transition: 'all .2s ease',
    transform: !state.isFocused ? 'rotate(90deg)' : null
  }),
  indicatorSeparator: () => {
    return {
      display: "none",
    };
  },
  control: (provided: any, state:any) => {
    return {
      ...provided,
      background: "#F3F3F3",
      border: state.isFocused ? 0 : 0,
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      boxSizing: "border-box",
      transition: "all .25s ease",
      fontSize: "18px",
      wordBreak: "break-word",
      color: "#455B66",
      marginTop: "5px",
      cursor: "pointer",
      minHeight:"35px",
      borderRadius:0,
      position:"relative",
      "&:after":{
        content:'""',
        position:"absolute",
        bottom:"2px",
        width:"100%",
        height:"2px",
        background:"#0058A9",
      },
    };
  },
  menu: (provided: any) => {
    return {
      ...provided,
      border: "1px solid #D0D9DE",
      boxSizing: "border-box",
    }
  },
  menuList: (provided: any) => {
    return {
      ...provided,
      minHeight: "300px"
    }
  }
};

const DropdownSearch = React.memo(({
  options,
  value,
  onSelect,
  isMulti,
  className,
  label,
  isReversed,
  components,
    placeholder
}: IProps) => {

  return (
    <StyledDropdown className={className}>
      {label !== "" && <LabelStyled>{label}</LabelStyled>}
      <Select
        styles={customStyles as any}
        menuPlacement={isReversed ? "top" : "bottom"}
        className="searchable-dropdown"
        onChange={(e) => onSelect(e)}
        value={value}
        options={options}
        isMulti={isMulti}
        isRtl={true}
        placeholder={placeholder}
        isClearable={false}
        components={components}></Select>
    </StyledDropdown>
  );
});

export default DropdownSearch;
