import React, {useMemo} from "react";
import styled, {keyframes} from "styled-components";
import {IHistoryEmail} from "../../store/posts";
import {convertStringToDate, getFormatDateTime} from "../../utilites";
import msg from "../../assets/svg/email-icon.svg";
import trash from "../../assets/svg/trash-icon.svg";
import cross from "../../assets/svg/close-icon.svg";
import gotoIcon from "../../assets/svg/go-to.svg";
import {IPostCardClient} from "../../store/clients";

const FadeUp = keyframes`
    0%{
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;




interface IProps {
  item: IHistoryEmail;
  onClick: () => void;
  onDelete?: () => void;
  onSend?: () => void;
}


// return (
//     <Card onClick={onClick}>
//       <Content>
//         <Box>
//           <StyledTitle>{item.subject}</StyledTitle>
//           {onDelete && <StyledButton type="del" onClick={handleDelete} />}
//           {onSend && <StyledButton type="email" onClick={handleSend} />}
//         </Box>
//         <Box>
//           <User>
//             <UserPic src={UserImg} />
//             <UserName>{item?.user_name}</UserName>
//           </User>
//           <StyledDate>{date}</StyledDate>
//         </Box>
//         <Text
//             className="inner_html"
//             dangerouslySetInnerHTML={{ __html: item.text }}
//         />
//         <Dots className="dots">...</Dots>
//         <FilesBox>
//           {item.attachments.map((a) => {
//             return (
//                 <File
//                     key={a.id}
//                     href={a.file}
//                     target="_blank"
//                     onClick={(e) => e.stopPropagation()}
//                 >
//                   <FilePic src={FileLogo} />
//                   <p>{a.file_name}</p>
//                 </File>
//             );
//           })}
//         </FilesBox>
//       </Content>
//       <ClientsBox>
//         {item.clients.map((c) => (
//             <ClientName key={c.id}>{c.name}</ClientName>
//         ))}
//       </ClientsBox>
//     </Card>
// );
const HistoryCard = React.memo(({ item, onClick, onDelete, onSend }: IProps) => {
  const handleDelete = (e: React.MouseEvent<HTMLOrSVGElement>) => {
    e.stopPropagation();
    if (onDelete) onDelete();
  };

  const handleSend = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (onSend) onSend();
  };

  const date: string = useMemo(() => {
    if (item?.datetime) {
      const date = convertStringToDate(item?.datetime).getTime()
      const offset = new Date().getTimezoneOffset() * 60 * 1000;
      return  getFormatDateTime(new Date(date - offset))
    }
    return "";
  }, [item?.datetime]);

  return (
      <Container onClick = {onClick}>
        <Header>
          <Actions>
            {onDelete && <SvgButton onClick={handleDelete}>
              <Icon src = {trash}/>
            </SvgButton>}
            {onSend && (
                <SvgButton onClick={handleSend}>
                  <Icon src = {msg}/>
                </SvgButton>
            )}
          </Actions>
          <DateContainer>
            <SvgButton>
              <Icon src = {cross}/>
            </SvgButton>
            <StyledDate>{date}</StyledDate>
          </DateContainer>
        </Header>
        <TextContainer>
          <TextTitle>{item.subject}</TextTitle>
          <TextFooter>
            {item?.user_name && <TagLinkContainer>
              <Icon src = {gotoIcon}/>
              <TagLink>{item?.user_name}</TagLink>
            </TagLinkContainer>}
          </TextFooter>
          <Text  dangerouslySetInnerHTML={{ __html: item.text }}/>
        </TextContainer>
        {!!item.attachments.length && (
            <FilesBox>
              {item.attachments.map((a) => {
                return (
                    <File
                        key={a.id}
                        href={a.file}
                        target="_blank"
                        onClick={(e) => e.stopPropagation()}
                    >
                      <p>{a.file_name}</p>
                    </File>
                );
              })}
            </FilesBox>
        )}
        {!!item.clients.length && (
            <CustomersContainer>
              {item.clients?.map((client, index: number) => (
                  <Client
                      key={index}
                  >
                    {client.name}
                  </Client>
              ))}
            </CustomersContainer>
        )}
      </Container>
  )
});

export default HistoryCard;


const Container = styled.div`
  border-radius: 5px;
  border: 1px solid var(--Grey, #BCBCBC);
  background: #FFF;
  padding: 15px 20px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding-bottom: 25px;
  border-bottom: 1.5px solid #0058A9;
`
const Actions = styled.div`
    display: flex;
  gap: 30px;
  flex-direction: row-reverse;
  align-items: center;
`
const SvgButton = styled.div`
    all:unset;
    cursor: pointer;
`
const Icon = styled.img`
`

const DateContainer = styled.div`
    display: flex;
  gap: 30px;
  align-items: center;
`
const StyledDate = styled.div`
  
`

const TextContainer = styled.div`
  border-bottom: 1.5px solid #0058A9;
`

const Text = styled.div`
  position: relative;
  height: 100px;
  margin-bottom: 20px;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    transition: 200ms linear;
    box-shadow: inset 0 -20px 25px #ffffffee;
    z-index: 2;
  }
`;

const TextFooter = styled.div`
    width: 100%;
  display: flex;

  align-items: center;
  gap: 40px;
  margin-top: 20px;
`


const TagLinkContainer = styled.div`
    display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`
const TagLink = styled.div``

const TextTitle = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
`

const FilesBox = styled.div`
  display: flex;
  padding: 20px 0;
  width: 100%;
  margin-top: 20px;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
  border-top: 1.5px solid #0058A9;
`

const File = styled.a`
  color: #0058A9;
`
const CustomersContainer = styled.div`
  display: flex;
  gap: 20px;
  padding: 20px 0;
  flex-wrap: wrap;
  text-align: center;
  border-bottom: 1.5px solid #0058A9;
`
const Client = styled.div`
  color: #0058A9;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  
  &:hover{
    text-decoration: underline;
  }
`
